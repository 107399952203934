var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutWithoutMap',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Statistiques de la commune ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"w-full"},[(_vm.commune_stats.population_municipale.value !== 'NaN')?_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex md:flex-col lg:flex-col space-x-3 md:space-x-0 md:space-y-5 lg:space-x-0 lg:!space-y-5"},[_c('div',{staticClass:"flex flex-col space-y-2 w-11/12 full-hd:w-4/6 md:w-full md:grid md:grid-cols-2 md:space-y-0 md:gap-x-5 md:gap-y-4 lg:w-full lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-x-5 lg:gap-y-4 sm:flex sm:flex-col sm:w-full"},_vm._l((_vm.commune_stats),function(item,index){return _c('CommuneStats',{key:index,attrs:{"title":item.title,"value":item.value,"icon":item.icon}})}),1),_c('div',[_c('BarreChart',{staticClass:"h-full",attrs:{"libelle_total":"Population active","classes_canvas":"h-80 md:h-32","chartId":"Barre-chart-commune","title":"Catégories Socioprofessionnelles","labels":[
                'Agriculteurs exploitants',
                'Artisans, commerçants, chefs d’entreprise',
                'Cadres et professions supérieures',
                'Professions intermédiaires',
                'Ouvriers',
                'Retraités',
                'Sans activités' ],"dataset":_vm.socio_professionnelles,"without_format_euro":""}})],1)]),_c('div',{staticClass:"flex mt-6 space-x-6 md:flex-col md:space-x-0 md:space-y-3 lg:space-y-3 lg:flex-col lg:space-x-0 sm:space-y-5"},[_c('div',{staticClass:"w-full"},[_c('CircleChart',{attrs:{"chartId":"Circle-chart-commune-1","labels":['Maison', 'Appartement'],"title":"Type de logements","dataset":_vm.appartements_maisons,"with_percentage":""}})],1),_c('div',{staticClass:"w-full"},[_c('CircleChart',{attrs:{"chartId":"Circle-chart-commune-2","labels":['Propriétaires', 'Locataires'],"title":"Statut des habitants","dataset":_vm.habitants,"with_percentage":""}})],1),_c('div',{staticClass:"w-full"},[_c('CircleChart',{attrs:{"chartId":"Circle-chart-commune-3","labels":['- de 25 ans', 'de 25 à 64 ans', '+ de 65 ans'],"title":"Répartition de la population","dataset":_vm.part_personnes,"unit":"Total","with_percentage":""}})],1)])]):_c('empty-state',{staticClass:"h-4/5 mt-7 bg-transparent",attrs:{"message":'Ces informations ne sont pas disponibles pour cette adresse',"main_icon":'bot1',"classes_icon":"!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }