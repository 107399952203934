<template>
  <div class="w-full">
    <div
      class="
        flex flex-col flex-1
        w-full
        px-12
        md:px-5
        pb-20
        py-8
        space-y-6
        md:pb-3
      "
    >
      <div class="flex justify-between sm:flex-col">
        <h3 class="text-2xl md:text-xl font-bold text-promy-primary leading-9">
          <slot name="title"> </slot>
        </h3>
        <DownloadAndSendSummary :is_outside_map="true" class="sm:mt-4" />
      </div>
      <div class="flex flex-1 m-auto w-full max-w-[1440px]">
        <slot name="body"></slot>
      </div>
    </div>
    <div
      class="hidden sm:block sticky bottom-0 pt-60 bg-gradient-to-t from-white"
    >
      <pro-button
        class="
          px-6
          py-3
          border-promy-green-350
          rounded-none
          w-full
          font-semibold
        "
        btn="primary"
        @click="revelezPotentiel"
      >
        Révélez le potentiel
      </pro-button>
    </div>
  </div>
</template>
<script>
import DownloadAndSendSummary from './Actions/DownloadAndSendSummary.vue'
export default {
  components: {
    DownloadAndSendSummary,
  },
  methods: {
    revelezPotentiel() {
      this.$router.push({
        name: 'RevelationSteps',
        query: { id_address: $route.params.id_address },
      })
    },
  },
}
</script>
