<template>
  <LayoutWithoutMap>
    <template #title> Statistiques de la commune </template>
    <template #body>
      <div class="w-full">
        <div
          class="w-full"
          v-if="commune_stats.population_municipale.value !== 'NaN'"
        >
          <div
            class="flex md:flex-col lg:flex-col space-x-3 md:space-x-0 md:space-y-5 lg:space-x-0 lg:!space-y-5"
          >
            <div
              class="flex flex-col space-y-2 w-11/12 full-hd:w-4/6 md:w-full md:grid md:grid-cols-2 md:space-y-0 md:gap-x-5 md:gap-y-4 lg:w-full lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-x-5 lg:gap-y-4 sm:flex sm:flex-col sm:w-full"
            >
              <CommuneStats
                v-for="(item, index) in commune_stats"
                :key="index"
                :title="item.title"
                :value="item.value"
                :icon="item.icon"
              />
            </div>
            <div>
              <BarreChart
                libelle_total="Population active"
                classes_canvas="h-80 md:h-32"
                class="h-full"
                chartId="Barre-chart-commune"
                title="Catégories Socioprofessionnelles"
                :labels="[
                  'Agriculteurs exploitants',
                  'Artisans, commerçants, chefs d’entreprise',
                  'Cadres et professions supérieures',
                  'Professions intermédiaires',
                  'Ouvriers',
                  'Retraités',
                  'Sans activités',
                ]"
                :dataset="socio_professionnelles"
                without_format_euro
              />
            </div>
          </div>
          <div
            class="flex mt-6 space-x-6 md:flex-col md:space-x-0 md:space-y-3 lg:space-y-3 lg:flex-col lg:space-x-0 sm:space-y-5"
          >
            <div class="w-full">
              <CircleChart
                chartId="Circle-chart-commune-1"
                :labels="['Maison', 'Appartement']"
                title="Type de logements"
                :dataset="appartements_maisons"
                with_percentage
              />
            </div>
            <div class="w-full">
              <CircleChart
                chartId="Circle-chart-commune-2"
                :labels="['Propriétaires', 'Locataires']"
                title="Statut des habitants"
                :dataset="habitants"
                with_percentage
              />
            </div>
            <div class="w-full">
              <CircleChart
                chartId="Circle-chart-commune-3"
                :labels="['- de 25 ans', 'de 25 à 64 ans', '+ de 65 ans']"
                title="Répartition de la population"
                :dataset="part_personnes"
                unit="Total"
                with_percentage
              />
            </div>
          </div>
        </div>

        <empty-state
          v-else
          class="h-4/5 mt-7 bg-transparent"
          :message="'Ces informations ne sont pas disponibles pour cette adresse'"
          :main_icon="'bot1'"
          classes_icon="!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"
        />
      </div>
    </template>
  </LayoutWithoutMap>
</template>

<script>
import CommuneStats from './components/CommuneStats.vue'
import helpers from '@/mixins/helpers'
import LayoutWithoutMap from '../LayoutWithoutMap'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    CommuneStats,
    LayoutWithoutMap,
  },
  mixins: [helpers],
  data() {
    return {
      commune_stats: {
        population_municipale: {
          title: 'Population',
          value: null,
          icon: 'PopulationIcon',
        },
        taille_moyenne_des_menages: {
          title: 'Taille moyenne ménage',
          value: null,
          icon: 'MenageIcon',
        },
        densite: {
          title: 'Densité',
          value: null,
          icon: 'DensiteIcon',
        },
        part_des_pers_mariees: {
          title: 'Population mariée',
          value: null,
          icon: 'MaritalStatusIcon',
        },
        nombre_de_foyers_fiscaux_imposes1: {
          title: 'Foyers fiscaux imposés',
          value: null,
          icon: 'FoyerIcon',
        },
        taux_communal_TH: {
          title: 'Taux communal taxe habitation',
          value: null,
          icon: 'TauxCommunalIcon',
        },
        taux_communal_TFB: {
          title: 'Taux communal taxe foncière sur les propriétés bâties',
          value: null,
          icon: 'TFBIcon',
        },
        zone_climatique: {
          title: 'Zone climatique',
          value: null,
          icon: 'Zone',
        },
        nbr_parcelles: {
          title: 'Nbr de parcelles',
          value: null,
          icon: 'NbrParcelles',
        },
      },
      //charts related variables
      habitants: [],
      appartements_maisons: [],
      socio_professionnelles: [],
      part_personnes: [],
    }
  },
  computed: {
    ...mapGetters({
      infos_commune: 'address/infos_commune',
    }),
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.commune_stats.population_municipale.value = this.formatNumberFr(
        this.getNestedObject(
          this.infos_commune.donnees_statistiques,
          'population',
          'Population_municipale',
          'valeur',
        ),
      )
      this.commune_stats.taille_moyenne_des_menages.value = this.formatNumberFr(
        this.getNestedObject(
          this.infos_commune.donnees_statistiques,
          'population',
          'Taille_moyenne_des_menages',
          'valeur',
        ),
      )
      this.commune_stats.densite.value = this.densiteFilter(
        this.getNestedObject(
          this.infos_commune.donnees_statistiques,
          'population',
          'Densite',
          'valeur',
        ),
      )
      let part_des_pers_non_mariees = this.getNestedObject(
        this.infos_commune.donnees_statistiques,
        'population',
        'Part_des_pers_non_mariees',
        'valeur',
      )

      this.commune_stats.part_des_pers_mariees.value = this.isExist(
        part_des_pers_non_mariees,
      )
        ? `${this.formatNumberFr(100 - part_des_pers_non_mariees)} %`
        : undefined

      this.commune_stats.nombre_de_foyers_fiscaux_imposes1.value =
        this.formatNumberFr(
          this.getNestedObject(
            this.infos_commune.donnees_statistiques,
            'revenus',
            'Nombre_de_foyers_fiscaux_imposes1',
            'valeur',
          ),
        )
      this.commune_stats.taux_communal_TH.value = this.th_And_tfb_Filter(
        this.getNestedObject(
          this.infos_commune.donnees_statistiques,
          'taxe',
          'Taux_communal_TH',
          'valeur',
        ),
      )

      this.commune_stats.taux_communal_TFB.value = this.th_And_tfb_Filter(
        this.getNestedObject(
          this.infos_commune.donnees_statistiques,
          'taxe',
          'Taux_communal_TFB',
          'valeur',
        ),
      )
      this.commune_stats.zone_climatique.value = this.getNestedObject(
        this.infos_commune,
        'zone_climatique',
      )
      this.commune_stats.nbr_parcelles.value =
        this.infos_commune.total_parcelles

      // charts
      this.setHabitants()
      this.setAppartsMaisons()
      this.setSocioProfessionnelles()
      this.setPartPersonnes()
    },

    densiteFilter(value) {
      let rounded = this.$options.filters.roundDouble(value)
      return `${this.formatNumberFr(
        Number.parseFloat(rounded).toFixed(0),
      )} habitans/KM²`
    },
    marriageFilter(value) {
      return this.$options.filters.roundDouble(value)
    },
    th_And_tfb_Filter(value) {
      return `${this.formatNumberFr(value)} %`
    },
    //charts
    getValueCrossPopulation(param) {
      return this.getNestedObject(
        this.infos_commune.donnees_statistiques,
        'population',
        param,
        'valeur',
      )
    },
    setPartPersonnes() {
      let part_des_pers_agees_moins_de_25_ans = this.getValueCrossPopulation(
        'Part_des_pers_agees_moins_de_25_ans',
      )
      let part_des_pers_agees_de_25_a_64_ans = this.getValueCrossPopulation(
        'Part_des_pers_agees_de_25_a_64_ans',
      )
      let part_des_pers_agees_plus_de_65_ans = this.getValueCrossPopulation(
        'Part_des_pers_agees_plus_de_65_ans',
      )
      if (
        this.isExist(part_des_pers_agees_moins_de_25_ans) &&
        this.isExist(part_des_pers_agees_de_25_a_64_ans) &&
        this.isExist(part_des_pers_agees_plus_de_65_ans)
      ) {
        this.part_personnes.push(
          this.round(part_des_pers_agees_moins_de_25_ans),
          this.round(part_des_pers_agees_de_25_a_64_ans),
          this.round(part_des_pers_agees_plus_de_65_ans),
        )
      }
    },
    setSocioProfessionnelles() {
      let agriculteurs_exploitants = this.getValueCrossPopulation(
        'Population_de_15_ans_ou+CSP_Agriculteurs_exploitants',
      )
      let artisans_commercants_chefs_entreprise = this.getValueCrossPopulation(
        'Population_de_15_ans_ou+CSP_Artisans_commercants_chefs_entreprise',
      )
      let cadres_prof_intellectuelles_superieures =
        this.getValueCrossPopulation(
          'Population_de_15_ans_ou+CSP_Cadres_et_professions_intellectuelles_superieures',
        )
      let professions_intermediaires = this.getValueCrossPopulation(
        'Population_de_15_ans_ou+CSP_Professions_intermediaires',
      )
      let ouvriers = this.getValueCrossPopulation(
        'Population_de_15_ans_ou+CSP_Ouvriers',
      )
      let retraites = this.getValueCrossPopulation(
        'Population_de_15_ans_ou+CSP_Retraites',
      )
      let sans_activites = this.getValueCrossPopulation(
        'Population_de_15_ans_ou+CSP_Autres_personnes_sans_activite_professionnelle',
      )
      if (
        this.isExist(agriculteurs_exploitants) &&
        this.isExist(artisans_commercants_chefs_entreprise) &&
        this.isExist(cadres_prof_intellectuelles_superieures) &&
        this.isExist(professions_intermediaires) &&
        this.isExist(ouvriers) &&
        this.isExist(retraites) &&
        this.isExist(sans_activites)
      ) {
        this.socio_professionnelles.push(
          this.round(agriculteurs_exploitants),
          this.round(artisans_commercants_chefs_entreprise),
          this.round(cadres_prof_intellectuelles_superieures),
          this.round(professions_intermediaires),
          this.round(ouvriers),
          this.round(retraites),
          this.round(sans_activites),
        )
      }
    },
    setAppartsMaisons() {
      let maisons = this.getValueCrossPopulation(
        'Part_des_maisons_dans_le_total_des_logements',
      )

      let appartements = this.getValueCrossPopulation(
        'Part_des_appartements_dans_le_total_des_logements',
      )

      if (this.isExist(maisons) && this.isExist(appartements)) {
        this.appartements_maisons.push(
          this.round(maisons),
          this.round(appartements),
        )
      }
    },
    setHabitants() {
      let proprietaires = this.getValueCrossPopulation(
        'Part_des_proprietaires_dans_les_res._principales',
      )

      let locataires = this.getValueCrossPopulation(
        'Part_des_locataires_dans_les_res._principales',
      )

      if (this.isExist(proprietaires) && this.isExist(locataires)) {
        this.habitants.push(this.round(proprietaires), this.round(locataires))
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$destroy()
    next()
  },
}
</script>

<style>
@media (max-width: 1024px) {
  #Circle-chart-commune-1,
  #Circle-chart-commune-2,
  #Circle-chart-commune-3 {
    height: 246px !important;
  }
}
@media (max-width: 639px) {
  #Circle-chart-commune-1,
  #Circle-chart-commune-2,
  #Circle-chart-commune-3 {
    height: 122px !important;
  }
}
</style>
